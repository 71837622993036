import React, { useEffect, useState } from 'react';
import { Container, Grid, Typography, Box, Divider  } from '@mui/material';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Header from './Header'; // Adjust the path as needed
import Footer from './Footer';
import AgriculturalFacts from './AgriculturalFacts';
import './Tractor.css';

import { faTractor } from '@fortawesome/pro-solid-svg-icons';
import { faChair, faLoveseat } from '@fortawesome/pro-regular-svg-icons';

const TractorAnimation = () => {
  const [animationClass, setAnimationClass] = useState('');
  const [key, setKey] = useState(0); // Key to force re-render
  const [style, setStyle] = useState({});

  const runAnimation = () => {
    // Define your animations
    const animations = [
      'moveTractorHorizontal1',
      'moveTractorHorizontal2',
      'moveTractorVertical1',
      'moveTractorVertical2',
    ];

    // Randomly choose an animation
    const selectedAnimation = animations[Math.floor(Math.random() * animations.length)];

    // Update style based on selected animation
    let newStyle;
    switch (selectedAnimation) {
      case 'moveTractorHorizontal1':
        newStyle = { bottom: '800px', left: '900px' }; // Example position
        break;
      case 'moveTractorHorizontal2':
        newStyle = { bottom: '500px', left: '900px' }; // Example position
        break;
      case 'moveTractorVertical1':
        newStyle = { left: '800px', bottom: '700px' }; // Example position
        break;
      case 'moveTractorVertical2':
        newStyle = { left: '1075px', bottom: '700px' }; // Example position
        break;
      default:
        newStyle = {}; // Fallback style
    }

    // Set the animation class to start the animation
    setAnimationClass(selectedAnimation);
    setStyle(newStyle);

    // Wait for animation to complete plus a random delay before resetting
    const animationDuration = 4000; // Example: 20 seconds, adjust based on your CSS
    const randomDelay = Math.random() * 5000; // Random delay up to 5 seconds
    setTimeout(() => {
      // Reset for next animation
      setAnimationClass('');
      setKey(prevKey => prevKey + 1); // Increment key to force re-render
      runAnimation(); // Recursively start next animation after a delay
    }, animationDuration + randomDelay);
  };

  useEffect(() => {
    runAnimation(); // Start the initial animation
    // Cleanup function to clear timeout if the component unmounts
    return () => clearTimeout(runAnimation);
  }, []); // Empty dependency array ensures this runs once on mount

  return (
    <div key={key} className={`tractor-animation ${animationClass}`} style={{ ...style, position: 'absolute' }}>
      <FontAwesomeIcon icon={faTractor} size="2x" />
    </div>
  );
};

const colorsDef = {
  // error: {
  //   border: 'red',
  //   background: 'rgba(255, 0, 0, 0.1)',
  // },
  // duplicate: {
  //   border: 'orange',
  //   background: 'rgba(255, 165, 0, 0.1)',
  // },
  ok: {
    border: 'rgb(13, 95, 49)',
    background: 'rgba(13, 95, 49, 0.2)',
  },
  authority: {
    border: 'blue',
    background: 'rgba(0, 0, 200, 0.2)',
  },
}

const getSeatIconColor = ({ type, isOccupied }) => {
  if(type === 'vip')
    return isOccupied ? colorsDef.authority.border : colorsDef.authority.background
  else
    return isOccupied ? colorsDef.ok.border : colorsDef.ok.background
}

const GfxSeat = (seat) => {
  return (
    <FontAwesomeIcon
      icon={faLoveseat}
      style={{ margin: '2px', color: getSeatIconColor(seat) }}
      title={`Seat ID: ${seat.id}${seat.type ? ` (${seat.type.toUpperCase()})` : ''} ${seat.isOccupied ? 'Occupied' : 'Available'}`}
    />
  )
}

const GfxSector = ({ name, seats }) => {
  return (
    <Grid item xs={12} sm={4} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <h2 style={{ textAlign: 'center', margin: '3px 0', color: 'rgb(3, 114, 52)' }}>{name}</h2>

      { seats.map((row, rowIndex) => (
        <div key={rowIndex} style={{ display: 'flex', justifyContent: 'center', margin: '2px' }}>
          { row.map((seat, seatIndex) => (
            <GfxSeat key={seatIndex} {...seat} />
          ))}
        </div>
      ))}

    </Grid>
  )
}

const GfxMap = ({ seatData }) => {
  return (
    <Box sx={{ width: '55%', display: 'flex', justifyContent: 'center', alignItems: 'center',  padding: '10px' }}>
      <Grid container justifyContent="center" spacing={2} sx={{ maxWidth: '90%', margin: '0 auto' }}>

        { seatData.map((row, vIndex) => (
          <React.Fragment key={vIndex}>
            { row.map((sector, hIndex) => (
              <GfxSector key={hIndex} {...sector} />
            ))}
          </React.Fragment>
        ))}

      </Grid>
    </Box>
  )
}

const SeatsDisplay = ({ seatData }) => {
  return (
    <>
      <Header />
      <Container maxWidth="m" sx={{ mt: 1 }}>
        <Box sx={{
          maxWidth: '90.5%',
          margin: '0 auto',
          display: 'flex',
          justifyContent: 'space-around',
          alignItems: 'center',
          border: '3px solid',
          borderColor: 'rgb(13, 95, 49)',
          borderRadius: '8px',
          padding: '10px',
          bgcolor: 'background.paper',
        }}>

          {/* <TractorAnimation /> */}

          {/* <Box sx={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
            <DigitalClock />
          </Box> */}

          <GfxMap seatData={seatData} />
          
          {/* <Box sx={{ width: '20%', display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '10px'}}>
            <AgriculturalFacts />
          </Box> */}

        </Box>
      </Container>
      <Footer />
    </>
  );
}
export default SeatsDisplay;
import React, { useState, useEffect } from 'react';

const DigitalClock = () => {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const timerId = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => {
      clearInterval(timerId);
    };
  }, []);
  
    // Format time to HH:MM:SS
    const formatTime = (date) => {
        const hours = date.getHours().toString().padStart(2, '0');
        const minutes = date.getMinutes().toString().padStart(2, '0');
        const seconds = date.getSeconds().toString().padStart(2, '0');
        return `${hours}:${minutes}:${seconds}`;
      };

  return (
    <div style={{ fontFamily: 'DigitalClock' , fontSize: '60px', color: 'white', textAlign:'center' }}>
      {formatTime(time)}
    </div>
  );
};

export default DigitalClock;

import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import DigitalClock from './DigitalClock';

const Header = () => {
  return (
    <>
        <Container maxWidth="m">
            {/* Apply the background color to the Container or a Box within it */}
            <Box sx={{maxWidth: '90%', padding: 2, margin: '0 auto', backgroundColor:  'rgb(13, 95, 49)', color: 'white', borderRadius: '8px'}}>
                <Grid container spacing={2} alignItems="center">
                    <Grid item xs={6}>
                        <Typography variant="h5" component="h1" textAlign="center">
                            Assemblea Generale 2024
                        </Typography>
                        <Box sx={{ width: '100%', height: '5px', marginTop: 1, backgroundImage: 'linear-gradient(to right, green 33%, white 33%, white 66%, red 66%)' }} />
                    </Grid>
                    <Grid item xs={6}>
                        <div style={{ margin: '0 auto', width:'100%' }}> {/* Adjust the value as needed */}
                            <DigitalClock />
                        </div>
                    </Grid>
                </Grid>
            </Box>
        </Container>

        {/* Text Box Section */}
        {/* <Container maxWidth="m" sx={{ mt: 2 }}>
            <Box sx={{ maxWidth: '90%', padding: 2, margin: '0 auto',borderTop: 10, borderColor: 'rgb(13, 95, 49)' , backgroundColor: 'rgb(234,235,215)', borderRadius: '8px' , padding: 2 ,marginBottom: 2 }}>
                <Typography variant="h4" component="p" sx={{ mt: 2, fontWeight: 'bold', textAlign: 'center', color: 'rgb(13, 95, 49)'  }}>
                REGISTRAZIONE PARTECIPANTI
                </Typography>
            </Box>
        </Container> */}
    </>
  );
};

export default Header;